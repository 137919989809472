import React from 'react'
import { navigate } from 'gatsby'
import { faBahai } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Models
import { ChapterModel } from '../../../models'

// Styles
import {
  coverImg,
  coverItem,
  cornerIcon,
  coverPseudobutton,
} from './styles.module.scss'

interface ChapterItem extends ChapterModel {
  reading: boolean
  read: boolean
}

interface Props {
  chapter: Partial<ChapterItem>
}

const CoverSlide: React.FC<Props> = ({ chapter }) => {
  return (
    <div
      className={`${coverItem}`}
      onClick={() => {
        navigate(`/reader/${chapter?.series?.prefix}${chapter.number}`)
      }}
    >
      <img
        className={coverImg}
        src={`https://dilancovak.com/.img/covers/CoverCap${chapter?.series?.prefix}${chapter.number}.jpg`}
      />
      {chapter?.series?.prefix !== '' && (
        <div className={coverPseudobutton}>
          <FontAwesomeIcon icon={faBahai} />
        </div>
      )}
      {(chapter.reading || chapter.read) && (
        <img
          className={cornerIcon}
          src={
            chapter.reading
              ? 'https://www.dilancovak.com/.img/system/Reading.svg'
              : 'https://www.dilancovak.com/.img/system/Read.svg'
          }
        />
      )}
    </div>
  )
}

export default CoverSlide
