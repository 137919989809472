import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

// Components
import CoverListItem from '../../molecules/CoverListItem'

// Contexts
import UserContext from '../../../context/User/User.context'

// Utils
import { compareByNumber, comparebySeries, getFont } from '../../../utils/utils'

// graphQL
import { getGraphChapters } from '../../../graphQL'

// Models
import { ChapterModel } from '../../../models'

// Constants
import { USER_TOKEN } from '../../../config/constants/localStorage'

// Services
import { readByUser } from '../../../services/user'

// Style
import {
  volImg,
  textStyle,
  titleStyle,
  chevronIcon,
  blueWrapper,
  volImgMobile,
  yellowWrapper,
  blueContainer,
  wrapContainer,
  blockContainer,
  titleContainer,
  whiteContainer,
  volDescription,
  gradientWrapper,
  yellowContainer,
  stickyContainer,
  marginContainer,
  chevronContainer,
} from './styles.module.scss'

interface Props {
  localization: {
    chapter: string
  }
  language: string
}

interface ChapterItem extends ChapterModel {
  reading: boolean
  read: boolean
}

const ComicList: React.FC<Props> = ({ localization, language }) => {
  const allComics = getGraphChapters()
    .filter((comic) => comic.published)
    .sort(compareByNumber)
    .sort(comparebySeries) as ChapterItem[]

  const allVolumes = [
    {
      name: allComics[0].series.name,
      description: {
        ENG: 'Enjoy all of our chapters in this page. To reduce the amount you can see, use the arrows above to navigate through the volumes',
        ESP: 'Disfruta todos nuestros capítulos en esta página. Para reducir el número que puedes ver, usa las flechas para filtrar por volumen',
        ADN: '',
      },
      number: '0',
      chapters: allComics.length,
      id: 0,
    },
  ]

  allComics.forEach((chapter, index) => {
    if (index === 0) {
      allVolumes.push({
        name: chapter.volume.name,
        description: chapter.volume.description,
        number: `${chapter.series.prefix}${chapter.volume.number}`,
        chapters: allComics.filter(
          (ch) => ch.volume.id === chapter.volume.id && ch.published
        ).length,
        id: chapter.volume.id,
      })
    } else {
      if (!allVolumes.find((vol) => vol.id === chapter.volume.id)) {
        allVolumes.push({
          name: chapter.volume.name,
          description: chapter.volume.description,
          number: `${chapter.series.prefix}${chapter.volume.number}`,
          chapters: allComics.filter(
            (ch) => ch.volume.id === chapter.volume.id && ch.published
          ).length,
          id: chapter.volume.id,
        })
      }
    }
  })

  const [index, setIndex] = useState(0)
  const [vol, setVol] = useState(allVolumes[0])
  const [volComics, setVolComics] = useState<Partial<ChapterItem>[]>(allComics)
  const [readComics, setReadComics] = useState<number[]>([])

  const { user } = useContext(UserContext)

  useEffect(() => {
    const loadRead = async () => {
      const token = localStorage.getItem(USER_TOKEN)
      if (token) {
        const comics = await readByUser(token)
        setReadComics(comics)
      }
    }
    loadRead()
  }, [])

  useEffect(() => {
    setVol(allVolumes[index])
  }, [index])

  useEffect(() => {
    if (vol.id) {
      const filteredChapters = allComics.filter(
        (comic) => comic.volume.id === vol.id
      )
      setVolComics(filteredChapters)
    } else {
      setVolComics(allComics)
    }
  }, [vol])

  useEffect(() => {
    readComics.forEach((read) => {
      const readComic = allComics.find((comic) => read === Number(comic.id))
      if (readComic) readComic.read = true
    })
    setVolComics([...volComics])
  }, [readComics])

  useEffect(() => {
    allComics.forEach((comic) => {
      comic.reading = Number(user?.lastChapter) === Number(comic.id)
    })

    setVolComics([...volComics])
  }, [user?.lastChapter])

  return (
    <>
      <div className={`block-wrapper only-desktop ${gradientWrapper}`}>
        <div className={`container ${blockContainer}`}>
          <div className={blueContainer}>
            <img
              className={volImg}
              src={`https://dilancovak.com/.img/covers/CoverVol${vol.number}.jpg`}
            />
            <div className={stickyContainer}>
              <div className={titleContainer}>
                <div
                  className={chevronContainer}
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    if (index - 1 >= 0) {
                      setIndex(index - 1)
                    } else {
                      setIndex(allVolumes.length - 1)
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <FontAwesomeIcon
                    className={chevronIcon}
                    icon={faChevronLeft}
                  />
                </div>
                <h3 className={`${titleStyle} ${getFont('font-patua')}`}>
                  {vol.number === '0' ? '' : `Vol. ${vol.number}: `}
                  {vol.name[language]}
                </h3>
                <div
                  className={chevronContainer}
                  onClick={() => {
                    if (index + 1 < allVolumes.length) {
                      setIndex(index + 1)
                    } else {
                      setIndex(0)
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                  <FontAwesomeIcon
                    className={chevronIcon}
                    icon={faChevronRight}
                  />
                </div>
              </div>
              <p className={`${textStyle} ${getFont('font-patua')}`}>
                {vol.chapters} {localization.chapter}
              </p>
              <p className={`${textStyle} ${getFont('font-lato')}`}>
                {vol.description[language]}
              </p>
            </div>
          </div>
          <div className={yellowContainer}>
            {volComics.map((comic) => (
              <CoverListItem key={`${comic.id}_item`} chapter={comic} />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`block-wrapper only-mobile-tb ${yellowWrapper} ${stickyContainer}`}
      >
        <div className={`container ${blockContainer}`}>
          <div className={titleContainer}>
            <div
              className={chevronContainer}
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                if (index - 1 >= 0) {
                  setIndex(index - 1)
                } else {
                  setIndex(allVolumes.length - 1)
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <FontAwesomeIcon className={chevronIcon} icon={faChevronLeft} />
            </div>
            <h3 className={`${titleStyle} ${getFont('font-patua')}`}>
              {vol.number === '0' ? '' : `Vol. ${vol.number}: `}
              {vol.name[language]}
            </h3>
            <div
              className={chevronContainer}
              onClick={() => {
                if (index + 1 < allVolumes.length) {
                  setIndex(index + 1)
                } else {
                  setIndex(0)
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
              <FontAwesomeIcon className={chevronIcon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      </div>
      <div className={`block-wrapper only-mobile-tb ${yellowWrapper}`}>
        <div className={`container ${marginContainer}`} />
      </div>
      <div className={`block-wrapper only-mobile-tb ${blueWrapper}`}>
        <div className={`container ${blockContainer}`}>
          <div className={whiteContainer}>
            <img
              className={volImgMobile}
              src={`https://dilancovak.com/.img/covers/CoverVol${vol.number}.jpg`}
            />
            <div className={volDescription}>
              <p className={`${textStyle} ${getFont('font-patua')}`}>
                {vol.chapters} {localization.chapter}
              </p>
              <p className={`${textStyle} ${getFont('font-lato')}`}>
                {vol.description[language]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`block-wrapper only-mobile-tb ${blueWrapper}`}>
        <div className={`container ${blockContainer}`}>
          <div className={wrapContainer}>
            {volComics.map((comic) => (
              <CoverListItem key={`${comic.id}_item`} chapter={comic} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ComicList