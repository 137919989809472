import React from 'react'

// Components
import Comic from '../components/templates/Comic'
import PageTemplate from '../components/templates/PageTemplate'


const IndexPage = () => {
  return (
    <PageTemplate>
      <Comic />
    </PageTemplate>
  )
}

export default IndexPage
