// extracted by mini-css-extract-plugin
export var gradientWrapper = "styles-module--gradient-wrapper--uRHIF";
export var yellowWrapper = "styles-module--yellow-wrapper--4hN0C";
export var blueWrapper = "styles-module--blue-wrapper--8mUK+";
export var blockContainer = "styles-module--block-container--tLIOh";
export var yellowContainer = "styles-module--yellow-container--55SrS";
export var blueContainer = "styles-module--blue-container--S3NcB";
export var whiteContainer = "styles-module--white-container--G7D2b";
export var volDescription = "styles-module--vol-description--YFgMT";
export var volImg = "styles-module--vol-img--PlRAg";
export var volImgMobile = "styles-module--vol-img-mobile--yxI2Q";
export var titleStyle = "styles-module--title-style--EMGbI";
export var titleContainer = "styles-module--title-container--IKBah";
export var chevronContainer = "styles-module--chevron-container--QrE7L";
export var chevronIcon = "styles-module--chevron-icon--XRn82";
export var textStyle = "styles-module--text-style--hdYtq";
export var stickyContainer = "styles-module--sticky-container---MB2H";
export var marginContainer = "styles-module--margin-container--xuWQ7";
export var wrapContainer = "styles-module--wrap-container--uxkIP";